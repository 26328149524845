<template>
  <div class="min-h-screen bg-gray-200 dark:bg-gray-800 shadow-sm">
    <main class="py-5">

       <div class="box bg-white dark:bg-gray-900  rounded text-gray-900 dark:text-white  text-xl p-10">
          <h1 class="text-2xl font-bold text-gray-900 dark:text-white mb-5">
             Contact Us
            </h1>
<h2 class="bold my-5">If you have any questions, please send us an email at: </h2>
<code>{{email}} </code>
 
 
       </div>
    </main>
  </div>

  
</template>

<script>

export default {
  name: "Contact",
  data() {
    return {
      email: process.env.VUE_APP_EMAIL
    };
  }
}

</script>
